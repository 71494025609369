const brandContainer = document.querySelector('select#brand');

if (brandContainer) {
    brandContainer.addEventListener('change', (e) => {
        const brand = e.srcElement.value;
        const modelElement = document.querySelector('select[name="filter[model][]"]:not(.hidden)');
        const baseOption = modelElement.querySelector('option');

        const reset = () => {
            modelElement.innerText = '';
            modelElement.appendChild(baseOption);
            modelElement.disabled = true;
        };

        if (brand === '') {
            reset();
            return;
        }

        fetch(`/voorraad/${brand}/model/search/`)
            .then((Response) => Response.json())
            .then((json) => {
                reset();

                for (const [key, model] of Object.entries(json)) {
                    const option = document.createElement('option');
                    option.value = key;
                    option.innerText = model;
                    modelElement.appendChild(option);
                }

                modelElement.disabled = false;
                modelElement.focus();
            });
    });
}
