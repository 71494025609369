import '../styles/app.css';
import '../styles/form.css';

import { initCollapses } from 'flowbite/lib/cjs/components/collapse';
import './polyfills/polyfill';

import './editor/carousel/frontend';
import './editor/financial-lease-calculator/frontend';
import './editor/stock/carousel/frontend';
import './editor/stock/search/frontend';

initCollapses();
