for (const form of document.querySelectorAll('[data-financial-lease-calculator]')) {
    const targets = document.querySelectorAll(form.dataset.target);

    form.addEventListener('input', (e) => {
        e.preventDefault();

        const input = e.target;
        if (input.hasAttribute('max')) {
            let price = input.value.replaceAll(',', '.');
            price = price.replaceAll(' ', '');

            price = price.replaceAll(/[,.](?!\d{1,2}$)/gi, '');
            if (Number.parseFloat(price) > Number.parseFloat(input.max)) {
                input.value = new Intl.NumberFormat('nl-NL', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(input.max);
            }
        }

        fetch('/financial-lease/calculate', {
            method: 'POST',
            body: new FormData(form),
        })
            .then((response) => response.json())
            .then((json) => {
                if (!Object.prototype.hasOwnProperty.call(json, 'monthlyPayment')) {
                    throw new Error('Invalid response from server');
                }

                for (const target of targets) {
                    if (target.tagName === 'INPUT') {
                        target.value = json.monthlyPayment;
                    } else {
                        target.innerText = json.monthlyPayment;
                    }
                }
            });
    });

    form.dispatchEvent(new Event('input'));

    const submitButton = form.querySelector('[type=submit]');
    if (submitButton !== null) {
        submitButton.hidden = true;
        submitButton.classList.add('hidden');
        submitButton.classList.remove('action-btn');
    }
}
